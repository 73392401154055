
import { defineComponent } from "vue";
import Layout from "@/layouts/workspace/Layout.vue";

export default defineComponent({
  name: "AdminLayout",
  components: { Layout },
  setup() {
    return {
      menu: [
        {
          label: "instigator",
          to: "/admin/instigator",
        },
        { separator: true },
        { label: "activity", to: "/admin/activity" },
        { separator: true },
        {
          label: "user",
          to: "/admin/user",
        },
        { separator: true },
        {
          label: "subscription",
          to: "/admin/subscription",
        },
        { separator: true },
        {
          label: "sub-payment",
          to: "/admin/sub-payment",
        },
        { separator: true },
        {
          label: "workspace",
          to: "/workspace",
        },
      ],
    };
  },
});
